<template>
  <div class="modal" :class="showModalFlag ? 'is-active' : ''">
    <div class="modal-background"></div>
    <div v-if="showModalFlag" class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="showStudent">{{ studentName }}</p>
        <p class="modal-card-title" v-else>Loading.....</p>
        <button class="delete" aria-label="close" v-on:click="cancelModal()">
          >
        </button>
      </header>
      <section class="modal-card-body" v-if="showStudent">
        <article class="media tile is-child box">
          <div class="media">
            <div class="media-content">
              <p class="title is-4">{{ student.PupilFirstName }}</p>
              <p class="subtitle is-6">{{ student.PupilLastName }}</p>
              <p class="is-8">
                DOB: {{ student.dob(true) }} ({{ student.age() }})
              </p>
            </div>
            <div class="media-right">
              <figure class="image">
                <img
                  :src="`data:image/png;base64,${
                    student.photo ||
                    'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAALUlEQVR42u3NMQ0AAAgDsE051lHBQdIaaDM5VYFAIBAIBAKBQCAQCAQCwadgAbuSGAE8Ej4FAAAAAElFTkSuQmCC'
                  }`"
                  style="max-height: 100px"
                />
                <figcaption>
                  <i
                    :class="
                      student.gender() == 'M'
                        ? 'fa fa-person blue'
                        : 'fa fa-person-dress pink'
                    "
                  ></i>
                  / {{ student.tutor() }} / {{ student.candidateNumber() }}
                </figcaption>
              </figure>
            </div>
          </div>
        </article>

        <div class="tabs is-centered">
          <ul>
            <li
              v-if="student?.rtt"
              :class="tabActive == 'realtimetimetable' ? 'is-active' : ''"
            >
              <a @click="tabActive = 'realtimetimetable'">Realtime Timetable</a>
            </li>
            <li :class="tabActive == 'contacts' ? 'is-active' : ''">
              <a @click="tabActive = 'contacts'">Contact Details</a>
            </li>
            <li :class="tabActive == 'attributes' ? 'is-active' : ''">
              <a @click="tabActive = 'attributes'">Attributes</a>
            </li>

            <li :class="tabActive == 'teachers' ? 'is-active' : ''">
              <a @click="tabActive = 'teachers'">Teachers</a>
            </li>
          </ul>
        </div>

        <ContactList v-if="tabActive == 'contacts'" :student="student" />

        <AttributeList v-if="tabActive == 'attributes'" :student="student" />

        <TeacherList v-if="tabActive == 'teachers'" :student="student" />

        <RealTimeTimeTable
          v-if="tabActive == 'realtimetimetable'"
          :student="student"
        />
      </section>

      <section class="modal-card-body" v-else>
        <progress class="progress is-large is-primary" max="100">60%</progress>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" v-on:click="okModal()">Ok</button>
        <button class="button is-danger" v-on:click="missingStudent()">
          MISSING
        </button>

        <button
          v-if="canSeeMoreInfo"
          class="button is-info"
          v-on:click="$router.push('/school/student/' + student.PupilID + '/')"
        >
          More
        </button>
        <button class="button" v-on:click="cancelModal()">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { getStudent } from "../../classes/student";
import TeacherList from "Components/school/student/teacherlist.vue";

import AttributeList from "Components/school/student/attributelist.vue";
import ContactList from "Components/school/student/contactlist.vue";
import RealTimeTimeTable from "Components/school/student/realtimetimetable.vue";

export default {
  name: "modal-view-student",

  components: {
    TeacherList,
    AttributeList,
    ContactList,
    RealTimeTimeTable,
  },

  data: function () {
    return {
      showModalFlag: false,
      okPressed: false,
      student: null,
      loading: false,
      tabActive: "contacts",
      attributes: {},
      showStudent: false,
    };
  },

  computed: {
    runMode: function () {
      return this.$getConst("runMode");
    },

    studentName: function () {
      console.log(this.student);
      return this.student.name();
    },

    canSeeMoreInfo: function () {
      console.log("In can see more info");
      if (this.$store.getters.canPutStudentOnReport) {
        return true;
      }

      return this.runMode == "testing";
    },
    getAttributes: function () {
      if (!this.student) {
        return [];
      }
      let megs = this.student.getMEGS() ?? {};

      return Object.entries(megs)
        .filter((x) => x[0].indexOf(":") != -1)
        .map((a) => {
          let b = a[0];
          a = a[1];
          let c = b.indexOf(":");

          return {
            attribute: b,
            value: a,
            group: b.substring(0, c),
            name: b.substring(c + 1),
          };
        });
    },
  },
  methods: {
    wibble: async function () {
      console.log(this.student);

      let x = Object.keys(this.student);

      for (let k of x) {
        if (k.indexOf("/") == -1) {
          continue;
        }

        console.log(k);

        let ks = k.split("/");
        console.log(ks);

        let a = this.student;
        let y = ks.pop();

        while (ks.length) {
          let nk = ks.shift();

          console.log(nk);

          if (!a[nk]) {
            a[nk] = {};
          }

          a = a[nk];
        }

        a[y] = this.student[k];
        delete this.student[k];
      }
      console.log(this.student);
    },
    showModal: async function (student) {
      this.okPressed = false;
      this.showModalFlag = true;
      this.showStudent = false;
      this.loading = true;
      this.tabActive = "contacts";

      console.log({
        msg: "Helldaldlk399393939339399339393993939393939333933393939",
        student,
      });

      if (typeof student === "object" && typeof student.getID === "function") {
        this.student = student;
        this.showStudent = true;
        this.loading = false;
      } else {
        this.student = await getStudent(student);
        console.log({ loaded: this.student });
        this.loading = false;
        this.showStudent = true;
      }
    },
    okModal: function () {
      this.okPressed = true;
      this.showModalFlag = false;
    },
    cancelModal: function () {
      this.okPressed = false;
      this.showModalFlag = false;
    },
    missingStudent() {
      const x = this.$store.getters.currentUserDetails;

      const name = x.Title + " " + x.FirstName + " " + x.LastName;

      const message =
        this.student.PupilFirstName +
        " " +
        this.student.PupilLastName +
        "\n\nShould be in my lesson but is not here";
      this.$store.state.channel.publish("mainroom", {
        name,
        message,
        override: true,
      });
    },
  },
};
</script>

<style>
th.is-centered {
  text-align: center;
}
</style>
