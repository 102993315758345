<template>
  <div class="text:center">
    <table class="table" style="width: 100%">
      <thead>
        <tr>
          <th colspan="6" class="is-centered" style="text-align: center">
            for {{ timetable.date }}
          </th>
        </tr>
        <tr>
          <th style="text-align: center">Start</th>
          <th style="text-align: center">End</th>
          <th style="text-align: center">Subject</th>
          <th style="text-align: center">Teacher</th>
          <th style="text-align: center">Room</th>
          <th style="text-align: center"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in timetable.timetable"
          :key="row.periodIdent"
          :class="
            $store.state.currentHM >= row.startTime &&
            $store.state.currentHM <= row.endTime
              ? 'is-selected'
              : ''
          "
        >
          <td>{{ row.startTime }}</td>
          <td>{{ row.endTime }}</td>

          <td>{{ row.description }}</td>
          <td :style="row.isCover ? 'font-weight: 700' : ''">
            {{ row.staffName }}
          </td>
          <td :style="row.isCover ? 'font-weight: 700' : ''">
            {{ row.roomName }}
          </td>
          <td :style="row.isCover ? 'font-weight: 700' : ''">
            {{ row.isCover ? "*" : "" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "student-realtime-timetable",

  props: ["student"],

  computed: {
    timetable() {
      return this.student.getRTT(null, true);
    },
  },
};
</script>
