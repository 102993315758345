<template>
  <div class="text:center">
    <table class="table" style="width: 100%">
      <thead>
        <tr>
          <th>Attribute</th>
          <th style="text-align: right">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="attribute in getAttributes" :key="attribute.attribute">
          <td style="text-align: left">{{ attribute.attribute }}</td>
          <td style="text-align: right">{{ attribute.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "student-attributes-list",

  props: ["student"],

  computed: {
    getAttributes: function () {
      if (!this.student) {
        return [];
      }
      let megs = this.student.getMEGS() ?? {};

      return Object.entries(megs)
        .filter((x) => x[0].indexOf(":") != -1)
        .map((a) => {
          let b = a[0];
          a = a[1];
          let c = b.indexOf(":");

          return {
            attribute: b,
            value: a,
            group: b.substring(0, c),
            name: b.substring(c + 1),
          };
        });
    },
  },
};
</script>
